
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































.form-group {
  position: relative;
}

.form-feedback {
  position: static;

  @include mq('xl') {
    position: absolute;
  }
}
