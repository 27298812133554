
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























.ui-loading-dots__dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: $c-mustard;
  border-radius: 50%;
  animation: sending 1s infinite;

  & + & {
    margin-left: $spacing * 0.25;
    animation-delay: 0.1s;
  }

  &:last-child {
    animation-delay: 0.2s;
  }
}

@keyframes sending {
  25% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(0);
  }
}
