
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.form-group {
  position: relative;
  width: 100%;

  @include mq('s') {
    grid-column: 1 / 3;
  }
}

.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 260px;
  margin-top: 1rem;
  padding: 20px;
  resize: none;
  border: 1px solid $c-gray-light;
  border-radius: 4px;
}

.form-label__required {
  color: $c-mustard;
}
