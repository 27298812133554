
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































































































.form-group {
  width: 100%;

  @include mq(xs) {
    grid-column: 1 / 3;
  }
}

.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-files {
  margin-top: 1rem;
}

.form-files__info__label {
  color: $c-gray;
}

.form-files__item {
  position: relative;
  z-index: 11;
}

.form-files__item__name {
  display: flex;
  align-items: center;
  text-align: left;

  span {
    display: block;
    overflow: hidden;
    max-width: 30vw;
    text-overflow: ellipsis;
  }

  .icon {
    margin-right: math.div($spacing, 2);
  }

  @include mq(m) {
    span {
      max-width: 45vw;
    }
  }

  @include mq(m) {
    span {
      max-width: 25em;
    }
  }
}

.form-files__item__delete {
  @include mq($until: 's') {
    ::v-deep .action__label {
      display: none;
    }
  }
}

::v-deep {
  .file-preview-wrapper {
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    padding: math.div($spacing, 2) 0;
    border-top: 1px solid $c-light-grey !important; // stylelint-disable-line declaration-no-important

    &:first-child {
      margin-top: $spacing * 1.5 !important; // stylelint-disable-line declaration-no-important
    }
  }
}

@import '~vue-file-agent/dist/vue-file-agent.css';
